import React from "react";

const hocConnector = (ComposedClass, extraProps) => {

    class connectHOC extends React.PureComponent {
        render() {
            return <ComposedClass {...this.props} {...extraProps}  />
        }
    }

    return connectHOC
}

export default hocConnector;
