import React from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import routes from "../routes";
import Sidebar from "../components/Sidebar/Sidebar";
import Header from "../components/Header/Header";

class Admin extends React.Component {
    getRoutes = (routes) => {
        return routes.map((prop, key) => {
            if (prop.layout === "/admin") {
                return (
                    <Route
                        path={prop.layout + prop.path}
                        component={prop.component}
                        key={key}
                    />
                );
            } else {
                this.props.history.push("/admin/notFound");
            }
            return null
        });
    };

    render() {
        return (
            <div style={{display: 'flex'}}>
                <Sidebar
                    routes={routes}
                />
                <div className="main-content" ref="mainContent">
                    <div className={'header'}>
                        <Header/>
                    </div>
                    <div className={'content'}>
                        <Switch>
                            {this.getRoutes(routes)}
                            <Redirect from="*" to="/admin/dashboard"/>
                        </Switch>
                    </div>
                </div>
            </div>
        );
    }
}

export default Admin;
