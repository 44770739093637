import React from 'react'
import DashboardHOC from "./DashboardHOC";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import {Line} from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top'
        },
        title: {
            display: true,
            text: 'Chart.js Line Chart',
        },
    },
};

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

export const data = {
    labels,
    datasets: [
        {
            label: 'Dataset 1',
            data: [100, 200, 300, 400, 100, 550, 555],
            borderColor: 'rgb(255, 99, 132)',
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
        },
    ],
};

const Dashboard = ({...props}) => {
    const DashboardMain = DashboardHOC(
        ({
             text1
         }) => (
            <>
                <div>
                    <div style={{display: 'flex', marginBottom: '10px'}}>
                        <h2 style={{color: 'lightgrey'}}>Hi</h2>&nbsp;
                        <h2>Nimi,</h2>
                    </div>
                    <span style={{fontSize: 18, color: 'lightgrey'}}>{text1}</span>
                    <br/>

                    <div className={'row-card'}>
                        <div className={'row-inner-card'}>
                            <div className={'icon-card'}>
                                <i style={{fontSize: 25}} className={'icon-fa fa fa-image'}/>
                            </div>
                            <div className={'card-content'}>
                                <span style={{color: 'lightgrey'}}>Unassigned</span>
                                <span style={{color: 'black', fontSize: 25, fontWeight: 'bold'}}> 350</span>
                            </div>

                        </div>

                        <div className={'row-inner-card'}>
                            <div className={'icon-card'}>
                                <i style={{fontSize: 25}} className={'icon-fa fa fa-camera'}/>
                            </div>
                            <div className={'card-content'}>
                                <span style={{color: 'lightgrey'}}>Assigned</span>
                                <span style={{color: 'black', fontSize: 25, fontWeight: 'bold'}}> 450</span>
                            </div>

                        </div>

                        <div className={'row-inner-card'}>
                            <div className={'icon-card'}>
                                <i style={{fontSize: 25}} className={'icon-fa fa fa-arrow-right'}/>
                            </div>
                            <div className={'card-content'}>
                                <span style={{color: 'lightgrey'}}>Closed</span>
                                <span style={{color: 'black', fontSize: 25, fontWeight: 'bold'}}> 3500</span>
                            </div>

                        </div>

                        <div className={'row-inner-card'}>
                            <div className={'icon-card'}>
                                <i style={{fontSize: 25}} className={'icon-fa fa fa-message'}/>
                            </div>
                            <div className={'card-content'}>
                                <span style={{color: 'lightgrey'}}>Channels</span>
                                <span style={{color: 'black', fontSize: 25, fontWeight: 'bold'}}> 3</span>
                            </div>

                        </div>


                    </div>

                </div>
                <div className={'report-table'}>
                    <div className={'chart-table'}>
                        <div className={'table-content'}>
                            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                <span style={{color: 'black'}}>Today</span>&nbsp;&nbsp;
                                <i style={{fontSize: 15}} className={'fa fa-arrow-down'}/>
                            </div>
                            <div>
                                <Line options={options} data={data}/>;
                            </div>
                        </div>
                        <div className={'table-data'}>
                            <table className="table">
                                <thead>
                                <tr>
                                    <th scope="col">Teammates</th>
                                    <th scope="col">Assigned Conversations</th>
                                    <th scope="col">Closed Conversations</th>
                                </tr>
                                </thead>
                                <tbody style={{padding: '10px'}}>
                                <tr>
                                    <td className="custom-tr">
                                        <img
                                            src={require('../../assets/img/avatar.png')}
                                            alt={'profile'}
                                            style={{height: 25, width: 25, borderRadius: '50%', objectFit: 'contain'}}
                                        />&nbsp;&nbsp;
                                        Nini Martins
                                    </td>
                                    <td className="custom-tr">34</td>
                                    <td className="custom-tr">30</td>
                                </tr>
                                <tr>
                                    <td>
                                        <img
                                            src={require('../../assets/img/avatar.png')}
                                            alt={'profile'}
                                            style={{height: 25, width: 25, borderRadius: '50%', objectFit: 'contain'}}
                                        />&nbsp;&nbsp;
                                        Collins Iheagwana
                                    </td>
                                    <td>32</td>
                                    <td>40</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>
                    <div className={'side-leaderboard-container'}>
                        <div className={'side-leaderboard'}>
                            <div className={'top-heading'}>
                                <span style={{fontSize: 10, fontWeight: 'bold'}}>LEADERBOARD</span>
                                <i className={'fa fa-calendar'}/>
                            </div>
                            <div style={{
                                gap: '10px',
                                marginTop: '20px',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center'
                            }}>
                                <h6 style={{color: '#5F41B2'}}>Most Sales</h6>
                                <img
                                    src={require('../../assets/img/avatar.png')}
                                    alt={'profile'}
                                    style={{height: 60, width: 60, borderRadius: '50%', objectFit: 'contain'}}
                                />
                                <span style={{fontSize: 12, marginTop: '-5px', color: 'black'}}>Sarah Martins</span>
                                <h7 style={{fontWeight: 'bold', color: '#5F41B2'}}>50,000</h7>
                            </div>

                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '10px',
                                marginTop: '20px',
                                marginBottom: '30px'
                            }}>
                                <div className={'inside-leaderboard'}>
                                    <div className={'m-0 p-0'}
                                         style={{display: 'flex', gap: '10px', alignItems: 'center'}}>
                                        <span style={{fontSize: 10, fontWeight: 'bold', color: '#5F41B2'}}>2ND</span>
                                        <img
                                            src={require('../../assets/img/avatar.png')}
                                            alt={'profile'}
                                            style={{height: 20, width: 20, borderRadius: '50%', objectFit: 'contain'}}
                                        />
                                        <span
                                            style={{
                                                fontSize: 10,
                                                fontWeight: 'bold',
                                                color: '#5F41B2'
                                            }}>Nimi Martins</span>
                                    </div>
                                    <div className={'m-0 p-0'} style={{display: 'flex', alignItems: 'center'}}>
                                        <span style={{fontSize: 10, fontWeight: 'bold', color: '#5F41B2'}}>2300</span>
                                    </div>

                                </div>
                                <div className={'inside-leaderboard'}>
                                    <div className={'m-0 p-0'}
                                         style={{display: 'flex', gap: '10px', alignItems: 'center'}}>
                                        <span style={{fontSize: 10, fontWeight: 'bold', color: '#5F41B2'}}>3RD</span>
                                        <img
                                            src={require('../../assets/img/avatar.png')}
                                            alt={'profile'}
                                            style={{height: 20, width: 20, borderRadius: '50%', objectFit: 'contain'}}
                                        />
                                        <span
                                            style={{fontSize: 10, fontWeight: 'bold', color: '#5F41B2'}}>Yomi Ndu</span>
                                    </div>
                                    <div className={'m-0 p-0'} style={{display: 'flex', alignItems: 'center'}}>
                                        <span style={{fontSize: 10, fontWeight: 'bold', color: '#5F41B2'}}>2300</span>
                                    </div>

                                </div>
                                <div className={'inside-leaderboard'}>
                                    <div className={'m-0 p-0'}
                                         style={{display: 'flex', gap: '10px', alignItems: 'center'}}>
                                        <span style={{fontSize: 10, fontWeight: 'bold', color: '#5F41B2'}}>4TH</span>
                                        <img
                                            src={require('../../assets/img/avatar.png')}
                                            alt={'profile'}
                                            style={{height: 20, width: 20, borderRadius: '50%', objectFit: 'contain'}}
                                        />
                                        <span
                                            style={{
                                                fontSize: 10,
                                                fontWeight: 'bold',
                                                color: '#5F41B2'
                                            }}>Akin Siyanbola</span>
                                    </div>
                                    <div className={'m-0 p-0'} style={{display: 'flex', alignItems: 'center'}}>
                                        <span style={{fontSize: 10, fontWeight: 'bold', color: '#5F41B2'}}>2300</span>
                                    </div>

                                </div>

                            </div>


                        </div>
                    </div>

                </div>
            </>

        ),
        props
    );
    return <DashboardMain/>
}
export default Dashboard;