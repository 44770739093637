import React from "react";
import connectHoc from "../../commons/ConnectHoc";

const DashboardHOC = (ComposedComponent, props, type) => {
    class Dashboard extends React.PureComponent {

        state = {
            text: 'Hi Nimi,',
            text1: "I'ts looking like a slow day",
        }

        render() {
            const {text, text1} = this.state
            return (
                <ComposedComponent
                    {...this.props}
                    {...props}
                    text={text}
                    text1={text1}
                />
            )
        }

    }

    return connectHoc(Dashboard)
}
export default DashboardHOC