import React from "react";

const Header=()=>{
    return(
        <div className="mt-1" style={{width:'100%'}}>
            <div className="row" style={{width:'100%'}}>
                <div style={{width:'100%',display:'flex',justifyContent:'flex-end',alignItems:'center'}}>
                    <img
                        src={require('../../assets/img/avatar.png')}
                        alt={'profile'}
                        style={{ height:40,width:40,borderRadius:'50%',objectFit:'contain' }}
                    />&nbsp;&nbsp;
                    <i style={{color:'#846EC3',fontSize:15}}  className={'fa fa-arrow-down'}/>
                </div>
            </div>
        </div>
    )
}
export default Header;