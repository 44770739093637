import React from 'react';
import './sidebar.scss';
import {useHistory, useLocation} from "react-router-dom";

const Sidebar = ({routes}) => {
    const location = useLocation();
    const history = useHistory();
    return (
        <div className="sidebar">
            <div className={'ml-2'}>
                <img alt={'sidebar'} src={require('../../assets/img/sparkle.png')}/>
            </div>
            <span style={{color: 'black'}} className={'mt-4 ml-4 font-weight-bold'}>Inbox</span>
            <br/>
            <ul>
                {routes.map((item) => {
                    if (item.path !== '/notFound') {
                        const isActive = item.layout + item.path === location.pathname;
                        return (
                            <div className={'content'}
                                 style={{height: '12%', display: 'flex', alignItems: "flex-start"}}>
                                <li onClick={() => history.push(item.layout + item.path)} key={item.path}
                                    className={isActive ? 'active' : ''}>
                                    <i className={`${item.icon}`}></i> &nbsp;&nbsp;
                                    {item.name}
                                </li>
                            </div>
                        )
                    }
                    return null
                })}
            </ul>
        </div>
    );
};

export default Sidebar;