import Dashboard from "./containers/Dashboard/Dashboard";
import NotFound from "./containers/NotFound/NotFound";
import New from "./containers/New/New";


const routes = [
    {
        path: "/dashboard",
        name: "Dashboard",
        icon: "fa fa-home",
        component: Dashboard,
        layout: "/admin",
    },

    {
        path: "/notFound",
        name: "Not Found",
        // icon: "ni ni-tv-2 text-primary",
        component: NotFound,
        layout: "/admin",
    },
    {
        path: "/new",
        name: "New",
        icon: 'fa fa-inbox',
        component: New,
        layout: "/admin",
    },
    {
        path: "/assignToMe",
        name: "Assigned To Me",
        icon: 'fa fa-user',
        component: NotFound,
        layout: "/admin",
    },
    {
        path: "/mentioned",
        name: "Mentioned",
        icon: 'fa fa-at',
        component: NotFound,
        layout: "/admin",
    },
    {
        path: "/favourites",
        name: "Favourites",
        icon:'fa fa-star',
        component: NotFound,
        layout: "/admin",
    },
    {
        path: "/closed",
        name: "Closed",
        icon:'fa fa-close',
        component: NotFound,
        layout: "/admin",
    },
    {
        path: "/draft",
        name: "Draft",
        icon:'fa fa-pencil',
        component: NotFound,
        layout: "/admin",
    },
    {
        path: "/snoozed",
        name: "Snoozed",
        icon:'fa fa-phone',
        component: NotFound,
        layout: "/admin",
    },

]
export default routes;