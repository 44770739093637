import React from "react";

const New=()=>{
    return(
        <div>
            <strong>
                Hello this is me
            </strong>
        </div>
    )
}
export default New